/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* HEADER */

.alexzbaz {
  font-size: xx-large;
  cursor: pointer;
  font-family: monospace;
  color: #FFF;
}

.footer-toolbar {
  --background: var(--ion-color-white);
  border-top: 2px solid var(--ion-color-aliceblue);
  max-height: 44px;
}

ion-content {
  --background: var(--ion-color-white);
}

/* Phones */
@media only screen and (max-width: 480px) {
  .links-to-other-pages, .language {
    display: none;
  }

  .menu-button {
    font-size: xx-large;
    color: #FFF;
  }

  .active-header-link {
    --background: var(--ion-color-secondary);
  }
}

/* Tablets & Desktop */
@media only screen and (min-width: 481px) {
  .menu-button {
    display: none;
  }

  .links-to-other-pages {
    display: flex;
  }

  .active-header-link {
    background: var(--ion-color-secondary);
  }
}

.impressum-link {
  top: 0;
  position: absolute;
  right: 0;
  padding: 8px;
}

.language {
  --background: var(--ion-color-primary);
  color: #FFF;
}

.language-button {
  --background: var(--ion-color-primary);
}

.active-language {
  --background: var(--ion-color-secondary);
}

.language-selector {
  ion-item:nth-child(1) .sc-ion-select-popover-md {
    background: url("/assets/icon/Flag_of_Germany.svg");
    background-size: 30px;
    background-repeat: no-repeat;
    padding-left: 40px;
  }

  ion-item:nth-child(2) .sc-ion-select-popover-md {
    background: url("/assets/icon/Flag_of_the_United_Kingdom.svg");
    background-size: 30px;
    background-repeat: no-repeat;
    padding-left: 40px;
  }
}

ion-toolbar {
  --background: var(--ion-color-primary);
}

.toolbar-home {
  display: inline-flex;
}

.link {
  padding: 20px;
  font-size: 20px;
  color: #FFF;
}

.link:hover {
  background: var(--ion-color-secondary);
  cursor: pointer;
}
