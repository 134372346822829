// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: system-ui;

  /** primary **/
  --ion-color-primary: #0097DB;
  --ion-color-primary-rgb: 0, 151, 219;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0085c1;
  --ion-color-primary-tint: #1aa1df;

  /** secondary **/
  --ion-color-secondary: #CFAF2B;
  --ion-color-secondary-rgb: 207, 175, 43;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #b69a26;
  --ion-color-secondary-tint: #d4b740;

  /** aliceblue **/
  --ion-color-aliceblue: #F0F8FF;
  --ion-color-aliceblue-rgb: 240, 248, 255;
  --ion-color-aliceblue-contrast: #000000;
  --ion-color-aliceblue-contrast-rgb: 0, 0, 0;
  --ion-color-aliceblue-shade: #d3dae0;
  --ion-color-aliceblue-tint: #f2f9ff;

  /** xing **/
  --ion-color-xing: #126567;
  --ion-color-xing-rgb: 18, 101, 103;
  --ion-color-xing-contrast: #ffffff;
  --ion-color-xing-contrast-rgb: 255, 255, 255;
  --ion-color-xing-shade: #10595b;
  --ion-color-xing-tint: #2a7476;

  /** linkedin **/
  --ion-color-linkedin: #0e76a8;
  --ion-color-linkedin-rgb: 14, 118, 168;
  --ion-color-linkedin-contrast: #ffffff;
  --ion-color-linkedin-contrast-rgb: 255, 255, 255;
  --ion-color-linkedin-shade: #0c6894;
  --ion-color-linkedin-tint: #2684b1;

  /** twitter **/
  --ion-color-twitter: #00acee;
  --ion-color-twitter-rgb: 0, 172, 238;
  --ion-color-twitter-contrast: #000000;
  --ion-color-twitter-contrast-rgb: 0, 0, 0;
  --ion-color-twitter-shade: #0097d1;
  --ion-color-twitter-tint: #1ab4f0;

  /* text */
  --ion-color-text: #000;
  --ion-color-text-rgb: 0, 0, 0;
  --ion-color-text-contrast: #ffffff;
  --ion-color-text-contrast-rgb: 255, 255, 255;
  --ion-color-text-shade: #000000;
  --ion-color-text-tint: #1a1a1a;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** lightgray **/
  --ion-color-lightgray: #d3d3d3;
  --ion-color-lightgray-rgb: 211, 211, 211;
  --ion-color-lightgray-contrast: #000000;
  --ion-color-lightgray-contrast-rgb: 0, 0, 0;
  --ion-color-lightgray-shade: #bababa;
  --ion-color-lightgray-tint: #d7d7d7;

  /* light-background */
  --ion-color-light-background: #ffffff;
  --ion-color-light-background-rgb: 255, 255, 255;
  --ion-color-light-background-contrast: #000000;
  --ion-color-light-background-contrast-rgb: 0, 0, 0;
  --ion-color-light-background-shade: #e0e0e0;
  --ion-color-light-background-tint: #ffffff;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /* about-me-texts */
  --ion-color-about-me-texts: #eee;
  --ion-color-about-me-texts-rgb: 238,238,238;
  --ion-color-about-me-texts-contrast: #000000;
  --ion-color-about-me-texts-contrast-rgb: 0,0,0;
  --ion-color-about-me-texts-shade: #d1d1d1;
  --ion-color-about-me-texts-tint: #f0f0f0;

  /* zenit-blue */
  --ion-color-zenit-blue: #0097DB;
  --ion-color-zenit-blue-rgb: 0, 151, 219;
  --ion-color-zenit-blue-contrast: #ffffff;
  --ion-color-zenit-blue-contrast-rgb: 255, 255, 255;
  --ion-color-zenit-blue-shade: #0085c1;
  --ion-color-zenit-blue-tint: #1aa1df;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    /* primary */
    --ion-color-primary: #111;
    --ion-color-primary-rgb: 17, 17, 17;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0f0f0f;
    --ion-color-primary-tint: #292929;

    /* aliceblue */
    --ion-color-aliceblue: #333;
    --ion-color-aliceblue-rgb: 51, 51, 51;
    --ion-color-aliceblue-contrast: #ffffff;
    --ion-color-aliceblue-contrast-rgb: 255, 255, 255;
    --ion-color-aliceblue-shade: #2d2d2d;
    --ion-color-aliceblue-tint: #474747;

    /* white */
    --ion-color-white: #111;
    --ion-color-white-rgb: 17, 17, 17;
    --ion-color-white-contrast: #ffffff;
    --ion-color-white-contrast-rgb: 255, 255, 255;
    --ion-color-white-shade: #0f0f0f;
    --ion-color-white-tint: #292929;

    /* dark */
    --ion-color-dark: #ffffff;
    --ion-color-dark-rgb: 255, 255, 255;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #e0e0e0;
    --ion-color-dark-tint: #ffffff;

    /* zenit-blue */
    --ion-color-zenit-blue: #0097DB;
    --ion-color-zenit-blue-rgb: 0, 151, 219;
    --ion-color-zenit-blue-contrast: #ffffff;
    --ion-color-zenit-blue-contrast-rgb: 255, 255, 255;
    --ion-color-zenit-blue-shade: #0085c1;
    --ion-color-zenit-blue-tint: #1aa1df;

    /* light-background */
    --ion-color-light-background: #333;
    --ion-color-light-background-rgb: 51,51,51;
    --ion-color-light-background-contrast: #ffffff;
    --ion-color-light-background-contrast-rgb: 255,255,255;
    --ion-color-light-background-shade: #2d2d2d;
    --ion-color-light-background-tint: #474747;

    /* lightgray */
    --ion-color-lightgray: #111;
    --ion-color-lightgray-rgb: 17, 17, 17;
    --ion-color-lightgray-contrast: #ffffff;
    --ion-color-lightgray-contrast-rgb: 255, 255, 255;
    --ion-color-lightgray-shade: #0f0f0f;
    --ion-color-lightgray-tint: #292929;

    /* about-me-texts */
    --ion-color-about-me-texts: #222;
    --ion-color-about-me-texts-rgb: 34,34,34;
    --ion-color-about-me-texts-contrast: #ffffff;
    --ion-color-about-me-texts-contrast-rgb: 255,255,255;
    --ion-color-about-me-texts-shade: #1e1e1e;
    --ion-color-about-me-texts-tint: #383838;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
